import { graphql, navigate } from 'gatsby'
import moment from 'moment'
import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import SessionStorage from 'sessionstorage'
import { t, textWithLocale } from '../../../common/i18n'
import Carga from '../../assets/images/Talleres/carga.svg'
import Coffe from '../../assets/images/Talleres/coffee.svg'
import CocheSubstitucion from '../../assets/images/Talleres/icon_sustitucion.svg'
import Financiacion from '../../assets/images/Talleres/financiacion.svg'
import Guarda from '../../assets/images/Talleres/guarda.svg'
import Camion from '../../assets/images/Talleres/icon_camion.svg'
import Coche from '../../assets/images/Talleres/icon_coche.svg'
import Moto from '../../assets/images/Talleres/icon_moto.svg'
import Patinete from '../../assets/images/Talleres/icon_patinete.svg'
import Renting from '../../assets/images/Talleres/icon_renting.svg'
import Tractor from '../../assets/images/Talleres/icon_tractor.svg'
import Infantil from '../../assets/images/Talleres/icon_zona_infantil.svg'
import Laptop from '../../assets/images/Talleres/laptop.svg'
import Tienda from '../../assets/images/Talleres/tienda.svg'
import Vending from '../../assets/images/Talleres/vending.svg'
import Wc from '../../assets/images/Talleres/wc.svg'
import Wifi from '../../assets/images/Talleres/wifi.svg'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import HorariosTaller from '../../components/horariosTaller/HorariosTaller'
import InfoTaller from '../../components/infoTaller/InfoTaller'
import Layout from '../../components/Layout'
import ResponsiveSliderBanner from '../../components/ResponsiveSliderBanner/ResponsiveSliderBanner'
import Stars from '../../components/stars/Stars'
import TallerMap from '../../components/tallerMap/TallerMap'
import {
  setCitaPreviaStep,
  setSelectedTaller,
} from '../../context/actions/citaPreviaActions'
import AppContext from '../../context/context'
import { SeoData } from '../../types/Seo'
import { HorarioTallerFront, Taller } from '../../types/Taller'
import { getImages, ImageNodes } from '../../utils/imagesS3'
import route from '../../utils/route'
import { makeTallerSchema } from '../../utils/schema'
import { slugify } from '../../utils/slugify'
import styles from './taller.module.scss'
import './taller.scss'

const locale = process.env.GATSBY_LOCALE || 'es'
const placeholderTalleresImage = {
  Key: '',
  url: `https://${process.env.RODI_AWS_BUCKET}/talleres/default/taller/taller-mecanico-elpaso2000.jpg`,
  link: '',
  alt: 'Placeholder de Taller',
}

const placeholderTalleresRespImage = {
  Key: '',
  url: `https://${process.env.RODI_AWS_BUCKET}/talleres/default/responsable/encargado-default.jpg`,
  link: '',
  alt: 'Responsable del taller',
}

interface Props {
  data: PageData
}

const safeOpiniones = (opiniones) => {
  if (opiniones instanceof Array) {
    return opiniones
  }
  return []
}
const PageTemplate = ({ data }: Props) => {
  const { dispatch } = useContext(AppContext)
  const {
    alias,
    codigo_taller,
    provincia,
    cpostal,
    direccion,
    poblacion,
    telefono,
    geolocalizacion,
    email,
    responsable_taller,
    horario_lunvie_m_front,
    horario_lunvie_t_front,
    horario_sab_m_front,
    horario_sab_t_front,
    horario_dom_m_front,
    horario_dom_t_front,
    turismo,
    moto,
    patinete,
    camion,
    agricola,
    renting,
    wifi,
    lavabos,
    cafetera,
    vending,
    zona_infantil,
    guarda_neumaticos,
    zona_trabajo,
    financiacion,
    carga_moviles,
    tienda,
    opiniones,
    puntuacionMedia,
    porcentaje5,
    porcentaje4,
    porcentaje3,
    porcentaje2,
    empresa,
    region,
    porcentaje1,
    coche_substitucion,
  } = data.talleres
  let images = getImages(data.imagenes.nodes, locale, placeholderTalleresImage)
  if (!images.length) {
    images = getImages(data.default.nodes, locale, placeholderTalleresImage)
  }

  let nodesresp = getImages(
    data.resp_imagen.nodes,
    locale,
    placeholderTalleresRespImage
  )
  if (!nodesresp.length) {
    nodesresp = getImages(
      data.resp_default.nodes,
      locale,
      placeholderTalleresRespImage
    )
  }

  const breadCrumbItems = [
    { display: t('bread_crumb.inicio'), link: route('index') },
    {
      display: t('bread_crumb.talleres-mecanicos'),
      link: route('talleres.area', {
        area: '',
      }),
    },
    {
      display: provincia,
      link:
        process.env.GATSBY_WEB === 'rodimotor'
          ? ''
          : route('talleres.area', {
              area: slugify(provincia),
            }),
    },
    {
      display: alias,
    },
  ]

  useEffect(() => {
    const currentSearch = SessionStorage.getItem('searchValue')
    if (currentSearch !== null) {
      SessionStorage.setItem('hasSearch', true)
    }
  }, [])

  const goToCitaPrevia = () => {
    const taller = {
      codigo_taller,
      alias,
      direccion,
      poblacion,
      provincia,
      cpostal,
      telefono,
      empresa,
      region,
      codigo_sociedad: null,
    }
    dispatch(setSelectedTaller(taller, window.location.pathname))
    dispatch(
      setCitaPreviaStep(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].step)
    )
    navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].route)
  }
  require(`moment/locale/${locale}`)
  moment.locale(locale)

  return (
    <Layout
      selector={false}
      breadCrumItems={breadCrumbItems}
      seoData={data.seoData}>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(
            makeTallerSchema({
              name: t('ficha-taller.titulo', { alias, provincia }),
              url: `${process.env.GATSBY_WEB_URL}${
                data.seoData ? data.seoData[textWithLocale('url')] : ''
              }`,
              image: images.length
                ? images[0].url
                : 'https://images.rodi.es/not-found.png',
              streetAddress: direccion,
              addressLocality: poblacion,
              addressRegion: provincia,
              postalCode: cpostal,
              telephone: telefono,
              addressCountry: 'España',
              ratingValue: puntuacionMedia,
              ratingCount: safeOpiniones(opiniones).length,
              bestRating: Math.max.apply(
                Math,
                safeOpiniones(opiniones).map(function (o) {
                  return parseInt(o.puntuacion)
                })
              ),
            })
          ),
        }}
      />
      <section className={styles.ficha_talleres}>
        <h1 className={styles.title}>
          {t('ficha-taller.titulo', { alias, provincia })}
        </h1>

        <section className={styles.ficha_taller}>
          <InfoTaller
            cpostal={cpostal}
            direccion={direccion}
            poblacion={poblacion}
            telefono={telefono}
            email={email}
            responsable={responsable_taller}
            resp_imagen={nodesresp}
          />
          <HorariosTaller
            horario_lunvie_m_front={horario_lunvie_m_front}
            horario_lunvie_t_front={horario_lunvie_t_front}
            horario_sab_m_front={horario_sab_m_front}
            horario_sab_t_front={horario_sab_t_front}
            horario_dom_m_front={horario_dom_m_front}
            horario_dom_t_front={horario_dom_t_front}
            front_comentario={
              data.talleres[textWithLocale('front_comentario')] ||
              data.talleres.front_comentario
            }
          />
        </section>

        <section className={styles.media_taller}>
          <div className={styles.map}>
            <TallerMap fichaTaller={data.talleres} />
            <div className={styles.HTA_wrapper}>
              <a
                className={styles.HTA}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${geolocalizacion}`}>
                <FormattedMessage id="ficha-taller.directions" />
              </a>
            </div>
          </div>
          <ResponsiveSliderBanner
            desktopImages={images}
            mobileImages={images}
            sliderClass={styles.slider_wrapper}
          />
        </section>

        <section className={styles.servicios}>
          <h4>
            <FormattedMessage id="ficha-taller.services" />
          </h4>
          <div className={styles.servicios_cont}>
            <div className={styles.servicios_equip_cont}>
              <div className={styles.servicios_taller}>
                {turismo ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Coche />
                    </div>
                    <FormattedMessage id="ficha-taller.service-one" />
                  </div>
                ) : null}
                {moto ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Moto />
                    </div>
                    <FormattedMessage id="ficha-taller.service-two" />
                  </div>
                ) : null}
                {camion ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Camion />
                    </div>
                    <FormattedMessage id="ficha-taller.service-three" />
                  </div>
                ) : null}
                {agricola ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Tractor />
                    </div>
                    <FormattedMessage id="ficha-taller.service-four" />
                  </div>
                ) : null}
                {renting ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Renting />
                    </div>
                    <FormattedMessage id="ficha-taller.service-five" />
                  </div>
                ) : null}
                {patinete ? (
                  <div className={styles.servicios_taller_container}>
                    <div className={styles.icon}>
                      <Patinete />
                    </div>
                    <FormattedMessage id="ficha-taller.service-six" />
                  </div>
                ) : null}
              </div>
              <div className={styles.equip_taller}>
                {wifi ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Wifi />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-one" />
                  </div>
                ) : null}
                {cafetera ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Coffe />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-two" />
                  </div>
                ) : null}
                {guarda_neumaticos ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Guarda />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-three" />
                  </div>
                ) : null}
                {tienda ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Tienda />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-four" />
                  </div>
                ) : null}
                {carga_moviles ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Carga />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-five" />
                  </div>
                ) : null}
                {lavabos ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Wc />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-six" />
                  </div>
                ) : null}
                {financiacion ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Financiacion />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-seven" />
                  </div>
                ) : null}
                {zona_infantil ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Infantil />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-eight" />
                  </div>
                ) : null}
                {vending ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Vending />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-nine" />
                  </div>
                ) : null}
                {zona_trabajo ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <Laptop />
                    </div>
                    <FormattedMessage id="ficha-taller.equip-ten" />
                  </div>
                ) : null}
                {coche_substitucion ? (
                  <div className={styles.equip_taller_container}>
                    <div className={styles.icon_mini}>
                      <CocheSubstitucion />
                    </div>
                    <FormattedMessage id="ficha-taller.coche-substitucion" />
                  </div>
                ) : null}
              </div>
            </div>
            <button onClick={goToCitaPrevia} className={styles.HTA}>
              <FormattedMessage id="ficha-taller.appointment" />
            </button>
          </div>
        </section>
        <section className={styles.opiniones}>
          <h4>
            <FormattedMessage id="ficha-taller.opiniones" />
          </h4>
          <div className={styles.opiniones_cont}>
            <div className={styles.resume_cont}>
              <div className={styles.resume_stars_cont}>
                <div className={styles.stars}>
                  <Stars starsFilled={5} size={'big'} />
                </div>
                <div className={styles.stars_text}>
                  <div className={styles.value}>
                    {puntuacionMedia}{' '}
                    {t('ficha-taller.estrellas').toUpperCase()}
                  </div>
                  <div className={styles.total_coments}>
                    {safeOpiniones(opiniones).length}{' '}
                    {t('ficha-taller.opinion').toLowerCase()}
                  </div>
                </div>
              </div>
              <div className={styles.stars_list}>
                <div className={styles.list_item}>
                  <div className={styles.stars_item}>
                    5 {t('ficha-taller.estrellas').toLowerCase()}
                  </div>
                  <progress
                    className="progress is-warning"
                    style={{
                      height: '15px',
                      width: '150px',
                      borderRadius: '0px',
                      margin: '0px',
                    }}
                    value={porcentaje5}
                    max="100"></progress>
                  <div className={styles.percent}> {porcentaje5}%</div>
                </div>

                <div className={styles.list_item}>
                  <div className={styles.stars_item}>
                    4 {t('ficha-taller.estrellas').toLowerCase()}
                  </div>
                  <progress
                    className="progress is-warning"
                    style={{
                      height: '15px',
                      width: '150px',
                      borderRadius: '0px',
                      margin: '0px',
                    }}
                    value={porcentaje4}
                    max="100"></progress>
                  <div className={styles.percent}> {porcentaje4}%</div>
                </div>

                <div className={styles.list_item}>
                  <div className={styles.stars_item}>
                    3 {t('ficha-taller.estrellas').toLowerCase()}
                  </div>
                  <progress
                    className="progress is-warning"
                    style={{
                      height: '15px',
                      width: '150px',
                      borderRadius: '0px',
                      margin: '0px',
                    }}
                    value={porcentaje3}
                    max="100"></progress>
                  <div className={styles.percent}> {porcentaje3}%</div>
                </div>

                <div className={styles.list_item}>
                  <div className={styles.stars_item}>
                    2 {t('ficha-taller.estrellas').toLowerCase()}
                  </div>
                  <progress
                    className="progress is-warning"
                    style={{
                      height: '15px',
                      width: '150px',
                      borderRadius: '0px',
                      margin: '0px',
                    }}
                    value={porcentaje2}
                    max="100"></progress>
                  <div className={styles.percent}> {porcentaje2}%</div>
                </div>

                <div className={styles.list_item}>
                  <div className={styles.stars_item}>
                    1 {t('ficha-taller.estrellas').toLowerCase()}
                  </div>
                  <progress
                    className="progress is-warning"
                    style={{
                      height: '15px',
                      width: '150px',
                      borderRadius: '0px',
                      margin: '0px',
                    }}
                    value={porcentaje1}
                    max="100"></progress>
                  <div className={styles.percent}> {porcentaje1}%</div>
                </div>
              </div>
            </div>

            <div className={styles.coments_container}>
              {safeOpiniones(opiniones)
                .slice(0, 10)
                .map((item, index) => (
                  <div key={index} className={styles.coment_item}>
                    <div className={styles.coment_resume}>
                      <div className={styles.coment_stars}>
                        <Stars starsFilled={parseFloat(item.puntuacion)} />
                      </div>
                      <div className={styles.coment_user_cont}>
                        <div className={styles.coment_user}> {item.autor} </div>
                        <div className={styles.coment_date}>
                          {moment(item.fechaCreacion).startOf('day').fromNow()}
                        </div>
                      </div>
                    </div>
                    <div className={styles.coment_text}>{item.opinion}</div>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </section>
    </Layout>
  )
}

export default PageTemplate

interface OpinionData {
  autor: string
  opinion: string
  puntuacion: string
  fechaCreacion: string
}

interface TallerData
  extends Exclude<Taller, 'activo' | 'slug' | 'codigo_sociedad'>,
    HorarioTallerFront {
  geolocalizacion: string
  email: string
  responsable_taller: string
  turismo: boolean
  moto: boolean
  camion: boolean
  agricola: boolean
  patinete: boolean
  renting: boolean
  wifi: boolean
  lavabos: boolean
  cafetera: boolean
  vending: boolean
  zona_infantil: boolean
  guarda_neumaticos: boolean
  zona_trabajo: boolean
  financiacion: boolean
  carga_moviles: boolean
  tienda: boolean
  coche_electrico: boolean
  coche_substitucion: boolean
  puntuacionMedia: number
  porcentaje5: number
  porcentaje4: number
  porcentaje3: number
  porcentaje2: number
  porcentaje1: number
  opiniones: OpinionData[]
}
interface PageData {
  talleres: TallerData
  seoData: SeoData
  default: ImageNodes
  resp_default: ImageNodes
  imagenes: ImageNodes
  resp_imagen: ImageNodes
}

export const pageQuery = graphql`
  query currentPageQuery(
    $alias: String!
    $imgsrc: String!
    $respsrc: String!
    $url: String!
  ) {
    talleres(alias: { eq: $alias }) {
      codigo_taller
      direccion
      telefono
      empresa
      alias
      cpostal
      poblacion
      geolocalizacion
      provincia
      email
      responsable_taller
      horario_lunvie_m_front
      horario_lunvie_t_front
      horario_sab_m_front
      horario_sab_t_front
      horario_dom_m_front
      horario_dom_t_front
      front_comentario
      front_comentario_ca
      lat
      lon
      turismo
      region
      moto
      patinete
      camion
      agricola
      renting
      wifi
      lavabos
      cafetera
      vending
      zona_infantil
      guarda_neumaticos
      zona_trabajo
      financiacion
      carga_moviles
      tienda
      coche_electrico
      coche_substitucion
      puntuacionMedia
      porcentaje5
      porcentaje4
      porcentaje3
      porcentaje2
      porcentaje1
      opiniones {
        autor
        opinion
        puntuacion
        fechaCreacion
      }
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }

    default: allS3Asset(
      filter: { Key: { regex: "talleres/default/taller/[^/]+$/" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    resp_default: allS3Asset(
      filter: { Key: { regex: "talleres/default/responsable/[^/]+$/" } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    imagenes: allS3Asset(
      filter: { Key: { regex: $imgsrc } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
    resp_imagen: allS3Asset(
      filter: { Key: { regex: $respsrc } }
      sort: { order: ASC, fields: Key }
    ) {
      nodes {
        ...S3Images
      }
    }
  }
`
