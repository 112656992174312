import React from 'react'
import { FormattedMessage } from 'react-intl'
import Email from '../../assets/images/Talleres/icon_email.svg'
import Ubicacion from '../../assets/images/Talleres/icon_local.svg'
import Phone from '../../assets/images/Talleres/icon_phone.svg'
import { t } from '../../../common/i18n'
import styles from './index.module.scss'
import { ImageData } from '../../utils/imagesS3'

interface Props {
  direccion: string
  cpostal: string
  poblacion: string
  telefono: string
  email: string
  responsable: string
  resp_imagen: ImageData[]
}

export default function InfoTaller({
  direccion,
  cpostal,
  poblacion,
  telefono,
  email,
  responsable,
  resp_imagen,
}: Props) {
  return (
    <section className={styles.general_information}>
      <h3>{t('talleres.datos_taller')}</h3>
      <section className={styles.general_information__taller_details}>
        <div className={styles.taller_details__content}>
          <div className={styles.icon}>
            <Ubicacion />
          </div>
          <div className={styles.contain}>
            <p className={styles.title}>
              <FormattedMessage id="ficha-taller.address" />
            </p>
            <p>{direccion}</p>
            <p>
              {cpostal} {poblacion}
            </p>
          </div>
        </div>
        <div className={styles.taller_details__content}>
          <div className={styles.icon}>
            <Phone />
          </div>
          <div className={styles.contain}>
            <p className={styles.title}>
              <FormattedMessage id="ficha-taller.phone" />
            </p>
            <a href={`tel:${telefono}`}>{telefono}</a>
          </div>
        </div>
        <div className={styles.taller_details__content}>
          <img
            className={styles.icon_image_oficial}
            src={resp_imagen[0].url}
            alt={resp_imagen[0].alt}></img>

          <div className={styles.contain}>
            <p className={styles.title}>
              <FormattedMessage id="ficha-taller.responsible" />
            </p>
            <p>{responsable}</p>
          </div>
          <p></p>
        </div>
        <div className={styles.taller_details__content}>
          <div className={styles.icon}>
            <Email />
          </div>
          <div className={styles.contain}>
            <p className={styles.title}>
              <FormattedMessage id="ficha-taller.email" />
            </p>
            <p>{email}</p>
          </div>
        </div>
      </section>
    </section>
  )
}
