import React from 'react'
import styles from './horariosTaller.module.scss'
import { t } from '../../../common/i18n/index'
import { FormattedMessage } from 'react-intl'
import { HorarioTallerFront } from '../../types/Taller'

export default function HorariosTaller({
  horario_lunvie_m_front,
  horario_lunvie_t_front,
  horario_sab_m_front,
  horario_sab_t_front,
  horario_dom_m_front,
  horario_dom_t_front,
  front_comentario: tallerComment,
}: HorarioTallerFront) {
  return (
    <section className={styles.timings}>
      <h3>
        <FormattedMessage id="ficha-taller.horarios_taller" />
      </h3>
      <div className={styles.timings__weekdays_container}>
        <div className={styles.weekdays_container__days}>
          <p>
            <FormattedMessage id="ficha-taller.monday" />
          </p>
        </div>
        <div className={styles.weekdays_container__timings}>
          <p>{horario_lunvie_m_front !== '' ? horario_lunvie_m_front : ''}</p>
          <p>{horario_lunvie_t_front !== '' ? horario_lunvie_t_front : ''}</p>
        </div>
      </div>
      <div className={styles.holidays}>
        <div className={styles.holidays__days}>
          <p>
            <FormattedMessage id="ficha-taller.saturday" />
          </p>
        </div>
        <div className={styles.holidays__timings}>
          <p>{horario_sab_m_front || t('ficha-taller.cerrado')}</p>
          <p>{horario_sab_t_front || ''}</p>
        </div>
      </div>
      {(horario_dom_m_front || horario_dom_t_front) && (
        <div className={styles.holidays}>
          <div className={styles.holidays__days}>
            <p>
              <FormattedMessage id="ficha-taller.sunday" />
            </p>
          </div>
          <div className={styles.holidays__timings}>
            <p>{horario_dom_m_front || t('ficha-taller.cerrado')}</p>
            <p>{horario_dom_t_front || ''}</p>
          </div>
        </div>
      )}
      <p className={styles.comentario_front}>{tallerComment || ''}</p>
    </section>
  )
}
